// extracted by mini-css-extract-plugin
export var babyImage = "index-module--babyImage--epQ0b";
export var checkoutgrid = "index-module--checkoutgrid--9hzJA";
export var daddyImage = "index-module--daddyImage--j1yFj";
export var daddypanel = "index-module--daddypanel--bvWEh";
export var footer = "index-module--footer--df+gm";
export var indulgenceLogo = "index-module--indulgenceLogo--l1O1i";
export var intro = "index-module--intro--C4dpM";
export var introgrid = "index-module--introgrid--SfNtS";
export var jinglogoImage = "index-module--jinglogoImage--OknuR";
export var mommyImage = "index-module--mommyImage--dUtE1";
export var plate = "index-module--plate--ArPU0";
export var socialpanel = "index-module--socialpanel---VE6l";
export var tooltip = "index-module--tooltip--j2dB9";
export var twitterBtn = "index-module--twitterBtn--TxXLi";
export var youtubeBtn = "index-module--youtubeBtn--DZZIu";