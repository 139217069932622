import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const IndexPage = () => (
  <div>
    <Seo title="Home" />

    <div style={{ position: `relative`, zIndex: `1` }}>
                        

      <StaticImage
        src="../images/jing_web_bg.png"/>

      <StaticImage
        src="../images/hanging_plate.png"
        style={{zIndex:`9`,
              width:`16.55%`,
              top:`0%`,
              left:`10%`,
              position:`absolute`
              }} />

      <a href="https://twitter.com/JingGames" 
          title="Twitter">
        <StaticImage
          src="../images/button_twitter.png"
          className={styles.twitterBtn} />
      </a>

      <a href="https://www.youtube.com/channel/UCVuiiPpOIZ-wdjPyEo9KppQ"
          title="Youtube">
        <StaticImage
          src="../images/button_youtube.png"
          className={styles.youtubeBtn} />
      </a>

      <StaticImage className={styles.babyImage}
        src="../images/baby.png"
        title="Zzzz..." />

      <StaticImage className={styles.daddyImage}
        src="../images/daddy.png" 
        title="I don't like deadline."/>

      <div className={styles.tooltip}
            style={{width:`25%`,height:`7%`,top:`10%`,right:`22%`}}>
        <p><strong>Mr. Jing</strong>, game architect, coding dog, ux baker and sound forger.</p>
      </div>

      <div className={styles.tooltip}
            style={{width:`21%`,height:`7%`,top:`34%`,left:`10%`}}>
        <p><strong>Mrs. Jing</strong>, visual crafter, biz planner and cat writer.</p>
      </div>

      <div className={styles.tooltip}
            style={{width:`15%`,height:`7%`,top:`44%`,right:`10%`}}>
        <p><strong>Baby Jing</strong>, do cub things...</p>
      </div>

      <StaticImage className={styles.mommyImage}
        src="../images/mommy.png"
        title="Don't panic, everything is fine." />

      <StaticImage className={styles.jinglogoImage}
        src="../images/jing_logo.png" 
        title="The logo of Jing Games. A little vintage, we guess."/>

      <StaticImage className={styles.plate}
        src="../images/big_plate.png" />

      <a href="indulgence" className={styles.indulgenceLogo}
        title="Our fabulous game">
        <StaticImage 
          src="../images/logo_indulgence.png" />
      </a>

      <div className={styles.introgrid}>
        <p>Hello Stranger,
          <br/>
          Welcome to the Jing Games website!
           We are a two-person indie game studio dedicated to creating unique games that provide a deep experience for curious players. We hope you will enjoy our games.</p>
      </div>

      <div className={styles.checkoutgrid}>
      <p >
        Please check out our game 
      </p>
      </div>
      

      <div className={styles.footer}>
      <p style={{width:`100%`, maxWidth:`100%`}}>
        Contact <a href="mailto:contact@jing.games">Jing Games</a>
        <br/>
        © {new Date().getFullYear()}
      </p>
      </div>
    </div>

  </div>


);

export default IndexPage
